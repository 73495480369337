<template>
  <div :class="modal_name ? 'p-0' : 'px-4 pt-5 pb-4'" class="col-lg-12"> <!--col-lg-8 col-sm-12 col-xs-12 offset-lg-2-->

    <button v-if="!modal_name" class="uk-modal-close-default" type="button" uk-close @click="closeModal"></button>
    
    <!--Counter-->
    <div class="section-header border-0 uk-flex-middle justify-content-center">
      <div v-if="exam" class="section-header-center">
        <h4 class="mb-0">
          <span class="d-inline"> {{ $t('general.remaining_time') }}: <count-down :deadline="deadline" class="d-inline"
              @timeIsUp="timeIsUp"></count-down></span>
        </h4>
      </div>
    </div>

    <!--Messages-->
    <div class="uk-flex">
      <div class="col-lg-6 offset-lg-3 col-12 col-sm-6 offset-sm-3">
        <div v-if="errorMessages.length" class="uk-alert-danger text-center" uk-alert>
          <template v-for="(error, index) in errorMessages">
            <span v-if="error != null" :key="index">
              <a class="uk-alert-close" uk-close></a>
              <p class="m-0" style="color: red">
                <i class="uil-exclamation-triangle"></i> {{ error }}
              </p>
            </span>
          </template>
        </div>
        <div v-if="successMessage" class="uk-alert-success text-center" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: green">
            <i class="uil-exclamation-triangle"></i> {{ successMessage }}
          </p>
        </div>
      </div>
    </div>

    <!-- Card Body -->
    <div class="card-body actions-toolbar border-0 p-0">

      <div class="row">
        <div id="wizard_container" class="col-md-6 offset-md-3">
          <div id="top-wizard">
            <div id="progressbar"></div>
          </div>

          <!--Exam Result -->
          <div v-show="exam.show_result && exam_finished" class="exam-result">
            <div class="row text-center mb-3">
              <div class="col-lg-6 text-nowrap">
                <h4><span class="font-weight-bold">{{$t('exam.exam_question_count')}}: </span> {{ questionCount }}</h4>
              </div>
              <div class="col-lg-4 text-nowrap d-none">
                <h4><span class="font-weight-bold">{{ $t('exam.exam_right_wrong_question_count') }} :</span>
                  {{ right_answer_count }} / {{ wrong_answer_count }} </h4>
              </div>
              <div class="col-lg-6 text-nowrap">
                <h4><span class="font-weight-bold">{{$t('exam.exam_user_point')}} : </span> {{ user_point }}</h4>
              </div>
            </div>
          </div>

          <template v-for="(question, questionIndex) in questions">

            <!--Question & Options -->
            <div v-show="question.id === activeQuestion.id || exam_finished" :id="'question-' + question.id":data-id="question.id" class="step">
              <span class="count">{{ exam_finished ? questionIndex + 1 : currentQuestionNumber }} / {{ questionCount }}</span> 
              <h4 class="main_question mt-2" style="text-transform:none;">
                <div v-html="question.text"></div>
                <img v-if="question.media" :src="getEnvironmentMediaUrl(question.media)" alt="Görsel" class="image-preview"/>
              </h4>

              <hr>

              <div v-for="(option, option_index) in question.options" class="form-group">
                <label class="container_radio version_2 answer d-flex align-items-center">
                  <input :id="'question_' + question.id + '_answer_' + option.id" :data-id="option.id" :name="questionIndex"
                    :value="option.id" class="required" type="radio" @click="saveAnswer(question.id, option.id)">
                  <span class="checkmark"></span>
                   <img v-if="option.media" :src="getEnvironmentMediaUrl(option.media)" alt="Görsel" class="image-preview"/>
                  <span>{{ option.text }}</span>
                </label>
              </div>
            </div>

            <div v-if="question !== null && +question.question_type_id === 1 && false" :id="'question-' + question.id"
              :key="'new_question_box_' + questionIndex" :data-id="question.id" class="form-group row mb-3">
              <h6 class="col-12">{{ questionIndex + 1 + ". " + question.text }}</h6>
              <div v-for="(option, option_index) in question.options"
                :key="'question_' + questionIndex + '_answer_box_' + option_index" class="col-12 form-control-group">
                <div class="d-inline-flex align-items-center answer">
                  <input :id="'question_' + question.id + '_answer_' + option.id" :data-id="option.id"
                    :name="questionIndex" :value="option.id" class=" form-control mb-0 mr-1 line-height-1"
                    type="radio" />
                  <label :for="'question_' + questionIndex + '_answer_' + option_index"
                    class="small m-0 cursor-pointer">
                    {{ option.text }}</label>
                </div>
              </div>
            </div>

            <!--        <div
                        v-if="+question.question_type_id === 2"
                        :key="'question_box_' + index"
                        class="form-group row mb-3"
                    >
                      <h6 class="col-12">{{ index + 1 + ". " + question.text }}</h6>
                      <div class="col-12">
                        <span
                            v-for="(answer, answer_index) in question.options"
                            :key="'question_' + index + '_answer_box_' + answer_index"
                            :class="answer_index != 0 ? ' ml-2' : ''"
                            class="d-inline-flex align-items-center"
                        >

                          <input :id="'question_' + index + '_answer_' + answer_index"
                                 v-model="answer.id"
                                 class="d-inline-flex mb-0 mr-1 line-height-1" type="checkbox"/>
                          <label :for="'question_' + index + '_answer_' + answer_index"
                                 class="small m-0 cursor-pointer">
                            {{ answer.text }}</label>
                        </span>
                      </div>
                    </div>-->

            <!--
                    <div
                        v-if="+question.question_type_id === 1"
                        :key="'question_box_' + index"
                        class="form-group row mb-3"
                    >
                      <h6 class="col-12">{{ index + 1 + ". " + question.text }}</h6>
                      <div class="col-12">
                        <span
                            v-for="(answer, answer_index) in question.options"
                            :key="'question_' + index + '_answer_box_' + answer_index"
                            :class="+answer_index !== 0 ? ' ml-2' : ''"
                            class="d-inline-flex align-items-center"
                        >
                          <input
                              :id="'question_' + index + '_answer_' + answer_index"
                              v-model="answer.id"
                              :name="'question_' + index + '_radio_group'"
                              class="d-inline-flex mb-0 mr-1 line-height-1"
                              type="radio"
                          />

                          <label
                              :for="'question_' + index + '_answer_' + answer_index"
                              class="small m-0 cursor-pointer"
                          >
                            {{ answer.text }}</label
                          >
                        </span>
                      </div>
                    </div>
            -->

            <!--        <div
                        v-if="+question.question_type_id === 6"
                        :key="'question_box_' + index"
                        class="form-group row mb-3"
                    >
                      <h6 class="col-12">{{ index + 1 + ". " + question.text }}</h6>
                      <div class="col-12">
                        <textarea
                            :id="'question_' + index + '_answer_' + 0"
                            v-model="answer.id"
                            :placeholder="question.question_answers[0].text"
                        />
                      </div>
                    </div>-->
          </template>



        </div>

        <!-- /Wizard container -->
      </div>
    </div>




    <div id="bottom-wizard" class="exam-buttons">
      <button v-show="!exam_finished" :disabled="activeQuestionIndex <= 0" class="btn btn-primary ml-2 mr-2"
        type="button" @click="changeQuestion(1)">Önceki
      </button>
      <button v-show="!exam_finished" :disabled="activeQuestionIndex >= questionCount - 1"
        class="btn btn-primary mr-2 mr-2" type="button" @click="changeQuestion(2)">Sonraki
      </button>
    </div>

    <div id="bottom-wizard-submit" class="exam-buttons">
      <button v-if="!hideSubmit && activeQuestionIndex === questionCount - 1" v-show="!exam_finished"
        :disabled="disableSubmitButton" class="btn btn-info mr-2 mr-2" name="process" type="button" @click="onSubmit">{{
    $t("general.submit") }}
      </button>

      <button v-show="exam_finished" class="btn btn-info mr-2 mr-2" name="process" type="button" @click="closeModal">{{
        $t("general.close") }}
      </button>
    </div>
  </div>
</template>

<script>
import CountDown from "@/view/components/exam/CountDown";
import moment from "moment";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import {getEnvironmentMediaUrl} from "@/utils/imageHelper";

export default {
  name: "ExamQuestionList",
  components: {
    CountDown,
    DefaultLoading
  },
  props: {
    exam: {
      required: true,
    },
    modal_name: {
      required: true,
    },
    modal_title: {
      required: false,
    },
    hideSubmit: {
      required: false,
      default: false,
    }
  },
  data() {
    return {
      userAnswers: [],
      activeQuestionIndex: 0,
      errorMessages: [],
      successMessage: null,
      right_answer_count: 0,
      wrong_answer_count: 0,
      right_answers: [],
      user_answers: [],
      user_point: 0,
      exam_finished: false,
      disableSubmitButton: false
    };
  },
  computed: {
    currentQuestionNumber() {
      return this.activeQuestionIndex + 1;
    },
    activeQuestion() {
      return this.questions && this.questions.length ? this.questions[this.activeQuestionIndex] : null;
    },
    questionCount() {
      return this.exam && this.exam.questions ? this.exam.questions.length : 0
    },
    deadline() {
      let now = moment();
      let duration = this.exam.duration || 1;
      return now.add(duration, 'minutes').unix();
    },
    questions() {
      if (!this.exam.name) return [];
      let questions = [];
      return this.exam.questions;
    },
  },
  methods: {
    getEnvironmentMediaUrl,
    saveAnswer(questionId, answerId) {
      let item = this.userAnswers.find((item) => +item.questionId === +questionId)
      if (item) {
        this.userAnswers.map((item) => {
          if (+item.questionId === +questionId) {
            item.answerId = answerId;
            return item;
          }
        });
      } else {
        this.userAnswers.push({
          questionId: questionId,
          answerId: answerId,
        })
      }
    },
    changeQuestion(type = 1) {
      let self = this;
      self.errorMessages = [];
      let item = this.activeQuestion;

      let questionId = item.id;
      if (+type === 2) {
        let question = $("#question-" + questionId);
        let answerId = null;
        if (question.is(":visible")) {
          answerId = question.find(".answer input:checked").val();
        }

        if (!answerId) {
          self.errorMessages.push(self.$t("question.question_required"));
        } else {
          this.activeQuestionIndex++;
        }
      } else if (+type === 1) {
        if (this.activeQuestionIndex < 1) {
          this.activeQuestionIndex = 0;
        } else {
          this.activeQuestionIndex--;
        }
      }

      if (this.userAnswers[this.activeQuestionIndex]) {
        let checkedData = this.userAnswers[this.activeQuestionIndex];

        let checkedInput = $("#question_" + checkedData.questionId + '_answer_' + checkedData.answerId);
        checkedInput.prop("checked", true);
      }
    },
    showUserAnswers() {
      if (this.userAnswers && this.userAnswers.length) {

        this.userAnswers.forEach((user_answer) => {
          let userAnswer = $("#question_" + user_answer.questionId + '_answer_' + user_answer.answerId);
          // if (user_answer.is_correct) {
          //   userAnswer.parent().addClass("text-success");
          // } else {
            // userAnswer.parent().addClass("text-danger");

            // userAnswer.parent().addClass("checked");
            userAnswer.prop("checked", true);
          // }
        })
      }
    },
    showRightAnswers() {
      if (this.right_answers && this.right_answers.length) {
        this.right_answers.forEach((right_answer, index) => {
          let rightAnswer = $("#question_" + right_answer.id + '_answer_' + right_answer.answerId);
          rightAnswer.parent().addClass("text-success");
        })
      }
    },
    timeIsUp(value) {
      if (value) {
        this.disableSubmitButton = true;
        this.onSubmit();
      }
    },
    onSubmit() {
      let self = this;
      self.resetMessages();
      let questions = this.questions;
      let results = [];
      for (let i = 0; i < questions.length; i++) {
        let item = questions[i];
        let question = $("#question-" + item.id);
        let answerId = null;
        let answerType = 1;
        if (question.is(":visible")) {
          answerId = question.find(".answer input:checked").val();

          if (answerId) {
            results.push({
              questionId: item.id,
              answer: answerId,
            });
          }
        }
      }

      let validated = true;

      if (+this.questionCount !== +this.userAnswers.length) {
        self.errorMessages.push(self.$t("exam.answer_all_questions"));
      }

      if (validated) {
        self.disableSubmitButton = true;
        self.$emit("onSubmit", {
          results: this.userAnswers,
        },
          function (data) {
            self.disableSubmitButton = false;
            if (!data.status) {
              self.exam_finished = true;
              if (data.code !== 422) {
                self.errorMessages.push(self.$t("exam." + data.error.message.message));
              }

              if (data.error.message.hasOwnProperty('user_point')) {
                self.user_point = data.error.message.user_point;
              }

              if (self.exam.show_result||self.exam.show_result===1) {
                self.user_point = data.error.message.user_point
              }

              if (self.exam.show_right_answers||self.exam.show_right_answers===1) {
                self.right_answers = data.error.message.right_answers
                data.error.message.right_answers ? self.right_answers = data.error.message.right_answers : self.right_answers = data.message.right_answers
                self.showRightAnswers();
              }

              if (self.exam.show_user_answers||self.exam.show_user_answers===1) {
                // data.error.message.user_answers ? self.user_answers = data.error.message.user_answers : self.user_answers = data.message.user_answers
                self.showUserAnswers();
              }

              return true;
            } else {
              self.exam_finished = true;

              self.successMessage = self.$t('exam.' + data.data.message);

              if (self.exam.show_result||self.exam.show_result===1) {
                self.user_point = data.data.user_point
              }
              if (self.exam.show_right_answers||self.exam.show_right_answers===1) {
                self.right_answers = data.data.right_answers
                self.showRightAnswers();
              }

              if (self.exam.show_user_answers||self.exam.show_user_answers===1) {
                // self.user_answers = data.data.user_answers
                self.showUserAnswers();
              }
            }
          }
        );
      }

      // for (const [question_id, answer] of Object.entries(self.answers)) {
      //   if (answer == null || answer.length == 0 || (typeof answer == 'string' && answer.length < 3)) {
      //     self.errorMessages[self.question_indexes[question_id]] = self
      //       .$t("question.question_required")
      //       .replace(":NAME", self.question_indexes[question_id] + 1);
      //   }
      // }
      // if (self.errorMessages.length == 0) {
      //   self.$emit(
      //     "onSubmit",
      //     {
      //       answers: self.answers,
      //     },
      //     function (data) {
      //       if (!data.status) {
      //         if (data.code != 422) {
      //           self.errorMessages.push(self.$t("errors." + data.code));
      //           return true;
      //         }
      //         self.showErrors(data.error.errors);
      //       }
      //     }
      //   );
      // }
    },
    showErrors(errors) {
      this.resetMessages();
      for (const [field, error] of Object.entries(errors)) {
        if (Array.isArray(error)) {
          error.forEach((text) => {
            this.errorMessages.push(text);
          });
        } else this.errorMessages.push(error);
      }
    },
    closeModal() {
      this.$emit('onClose', true);
      if (this.modal_name) this.$modal.hide(this.modal_name);
    },
    resetMessages() {
      this.errorMessages = [];
    },
  }
};
</script>
<style scoped>
.text-success {
  background-color: #90ec53 !important;
}

.text-danger {
  background-color: red !important;
}

.line-height-1 {
  line-height: 1rem;
}


body {
  background: #fff;
  font-size: .875rem;
  line-height: 1.4;
  font-family: "Work Sans", Arial, sans-serif;
  color: #555
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222
}


ol,
ul {
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0
}

.form-group {
  position: relative
}

.form-group.terms {
  padding: 12px 0 0 0
}

.form-group i {
  font-size: 18px;
  font-size: 1.125rem;
  position: absolute;
  right: 5px;
  top: 11px;
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400 !important
}

.radio_input .error {
  left: -15px;
  top: -30px;
  right: inherit
}

.form-control {
  border: 1px solid #d2d8dd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
  font-size: .875rem;
  height: calc(2.55rem + 2px)
}

.form-control:focus {
  box-shadow: none;
  border-color: #ae71ff
}

/*

.container_radio {
  display: block;
  position: relative;
  font-size: 14px;
  font-size: .875rem;
  padding-left: 30px;
  line-height: 1.3;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.container_radio input {
  position: absolute;
  opacity: 0
}

.container_radio input:checked~.checkmark:after {
  opacity: 1
}

.container_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%
}

.container_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ae71ff;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}
.container_radio.version_2 {
  padding: 6px 0 0 45px;
  min-height: 30px
}

.container_radio.version_2 input:checked~.checkmark:before {
  opacity: 1
}

.container_radio.version_2 .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border: 1px solid #ccc;
  border-radius: 50%
}

.container_radio.version_2 .checkmark:after {
  width: 30px;
  height: 30px;
  top: -1px;
  left: -1px
}

.container_radio.version_2 .checkmark:before {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  left: 12px;
  top: 8px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  z-index: 999;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}
*/

.step {
  border: 1px solid #ccc;
  background-color:#fff;
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 1.4rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position:relative;
}

.step span.count{
    background:#ae71ff;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    position: absolute;
    top: -14px;
    font-size: 1rem;
}

.exam-buttons {
  margin-top: 15px;
  text-align: center;
}
</style>

<style>
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(80px);
  opacity: 0;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

ul {
  list-style-type: disc;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.uk-modal-body {
    padding: 1rem;
}

.vuejs-countdown li{
    background-color:#fff;
    padding:2px;
    border-radius: 5px;
}

img.image-preview{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width:25vw;
  border-radius:5px;
  background-color:#fff;
  padding:.5rem;
}

.container_radio{
  border-bottom:1px dotted #eee;
  padding:1rem 0 1rem 1rem;
  transition: all .3s ease-in-out;
  cursor:pointer;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px;
}

.container_radio input{
  margin:0 .5rem 0 0;
}

.container_radio:hover{
  background-color: #eee;
  border-radius: 5px;
}

.container_radio img.image-preview{
  width:200px;
  margin:0 1rem;
}
</style>
